import Axios from 'axios'
import config from '../utils/config'
import authHelper from '../utils/authHelper'
import handleResponseAuthorization from '../utils/responseHelper'

const baseUrl = config.API_BASE_URL

export interface ExportType {
  id: number
  name: string
}

export interface PayrollExportParameters {
  endDate: Date
  payrollWeek?: string
}

const exports: ExportType[] = [
  {
    id: 1,
    name: 'Sample Export'
  },
  {
    id: 2,
    name: 'Account Export'
  }
]

const getAvailableExports = (): ExportType[] => exports

const runPayrollExport = async (company: string, params: PayrollExportParameters) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  try {
    const response = await Axios.post(`${baseUrl}/export/${company}`, params, { responseType: 'arraybuffer', headers: authHelper.authHeader() })
    const fileName = response.headers['x-tipr-filename']
    const fileType = response.headers['content-type']
    return { fileName, data: response.data, fileType }
  } catch (error) {
    handleResponseAuthorization(error)
  }
}

export default {
  getAvailableExports,
  runPayrollExport
}

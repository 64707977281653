import React from 'react'
import { Message } from 'semantic-ui-react'

interface AlertProps {
  message: string
  negative?: boolean
  positive?: boolean
}

const Alert = ({ message, negative, positive }: AlertProps) => (
  <Message negative={negative} positive={positive}>
    {message}
  </Message>
)

export default Alert

import React, { useState, useEffect, ChangeEvent, SyntheticEvent } from 'react'
import './Dashboard.css'
import tipOrderService, {
    TipOrder,
    TipOrderSearchCriteria
} from '../services/tipOrderService'
import {
    Table,
    Dimmer,
    Loader,
    Segment,
    Form,
    Input,
    Button,
    Grid,
    InputOnChangeData,
    Icon,
    Pagination,
    PaginationProps,
    Container,
    Dropdown,
    DropdownProps
} from 'semantic-ui-react'
import { withRouter, RouteComponentProps } from 'react-router'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import FileSaver from 'file-saver'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { actionCreators } from '../store/Alert'
import tiprStoreService, { TiprStore } from '../services/tiprStoreService'

type TipOrderDashboardProps = RouteComponentProps & typeof actionCreators

const TipOrderDashboard: React.FC<TipOrderDashboardProps> = (
    props: TipOrderDashboardProps
) => {
    const aggregatorOptions = [
        {
            text: 'All',
            value: 'All'
        },
        {
            text: 'Doordash',
            value: 'DOORDASH'
        },
        {
            text: 'Doordash Pickup',
            value: 'Doordash Pickup'
        },
        {
            text: 'Google Food Delivery',
            value: 'Google Food Delivery'
        },
        {
            text: 'Google Food Pickup',
            value: 'Google Food Pickup'
        },
        {
            text: 'Grubhub',
            value: 'GRUBHUB'
        },
        {
            text: 'Grubhub Pickup',
            value: 'Grubhub Pickup '
        },
        {
            text: 'Panera',
            value: 'Panera'
        },
        {
            text: 'POSTMATES',
            value: 'POSTMATES'
        },
        {
            text: 'Postmates Pickup',
            value: 'Postmates Pickup'
        },
        {
            text: 'Uber Eats',
            value: 'Uber Eats'
        },
        {
            text: 'Uber Eats Pickup',
            value: 'Uber Eats Pickup'
        }

    ]

    const defaultStore: TiprStore = {
        id: 0,
        companyId: 0,
        paneraCompanyCode: '',
        _3PACompanyCode: '',
        storeNumber: 'All',
        franchiseStoreNumber: '',
        storeName: 'All',
        corporateName: '',
        franchise: '',
        hrCompanyCode: '',
        payrollWeek: '',
        openingDate: null,
        closingDate: null,
        dateCreated: null,
        dateImported: null
    }

    const [tipOrders, setTipOrders] = useState([] as TipOrder[])
    const [loaded, setLoaded] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [pageLoaded, setPageLoaded] = useState(false)

    // Search parameters
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [aggregator, setAggregator] = useState(aggregatorOptions[0].value)
    const [employeeId, setEmployeeId] = useState('')
    const [empFirstName, setEmpFirstName] = useState('')
    const [empLastName, setEmpLastName] = useState('')

    const [aggregatorOrderNumber, setAggregatorOrderNumber] = useState('')
    const [orderNumber, setOrderNumber] = useState('')
    const [storeNumber, setStoreNumber] = useState('All')
    const [stores, setStores] = useState<TiprStore[]>([defaultStore])
    const [loadingStores, setLoadingStores] = useState(true)

    useEffect(() => {
        tiprStoreService
            .getStoresForUser()
            .then(stores => {
                setStores([defaultStore, ...stores])
                setLoadingStores(false)
            })
            .catch(error => console.log('There was an error: ' + error))
    }, [])

    const handleStartDateChange = (value: Date) =>
        setStartDate(
            moment(value)
                .startOf('day')
                .toDate()
        )
    const handleEndDateChange = (value: Date) =>
        setEndDate(
            moment(value)
                .startOf('day')
                .toDate()
        )
    const handleAggregatorChange = (
        event: SyntheticEvent<HTMLElement>,
        data: DropdownProps
    ) => setAggregator(String(data.value))
    const handleEmployeeIdChange = (
        event: ChangeEvent<HTMLInputElement>,
        { value }: InputOnChangeData
    ) => setEmployeeId(value)
    const handleEmpFirstNameChange = (
        event: ChangeEvent<HTMLInputElement>,
        { value }: InputOnChangeData
    ) => setEmpFirstName(value)
    const handleEmpLastNameChange = (
        event: ChangeEvent<HTMLInputElement>,
        { value }: InputOnChangeData
    ) => setEmpLastName(value)

    const handleAggrOrderIdChange = (
        event: ChangeEvent<HTMLInputElement>,
        { value }: InputOnChangeData
    ) => setAggregatorOrderNumber(value)
    const handlePanOrderIdChange = (
        event: ChangeEvent<HTMLInputElement>,
        { value }: InputOnChangeData
    ) => setOrderNumber(value)
    const handleStoreChange = (
        event: SyntheticEvent<HTMLElement>,
        data: DropdownProps
    ) => setStoreNumber(String(data.value))

    const gatherSearchCriteria = (): TipOrderSearchCriteria => {
        const searchCriteria: TipOrderSearchCriteria = {}

        if (startDate !== null) {
            searchCriteria.startDate = startDate
        }
        if (endDate !== null) {
            searchCriteria.endDate = endDate
        }

        if (aggregator !== 'All') {
            searchCriteria.aggregator = aggregator
        }

        if (employeeId !== '') {
            searchCriteria.employeeId = Number(employeeId)
        }

        if (empFirstName !== '') {
            searchCriteria.firstName = empFirstName
        }

        if (empLastName !== '') {
            searchCriteria.lastName = empLastName
        }

        if (aggregatorOrderNumber !== '') {
            searchCriteria.aggrOrderId = aggregatorOrderNumber
        }

        if (orderNumber !== '') {
            searchCriteria.panOrderId = Number(orderNumber)
        }

        if (storeNumber !== '') {
            if (storeNumber.length === 4) {
                searchCriteria.storeNumber = '20' + storeNumber
            } else {
                searchCriteria.storeNumber = storeNumber
            }
        }

        return searchCriteria
    }

    const search = () => {
        setLoaded(false)
        const searchCriteria = gatherSearchCriteria()
        searchCriteria.pageNumber = pageNumber

        tipOrderService
            .searchTipOrders(searchCriteria)
            .then(pagedOrders => {
                setTipOrders(pagedOrders.data)
                setTotalPages(pagedOrders.totalPages)
            })
            .then(() => setLoaded(true))
            .catch(() => {
                setTipOrders(tipOrderService.defaultTipOrderList.data)
                setTotalPages(tipOrderService.defaultTipOrderList.totalPages)
                props.error('Error loading data')
            })
    }

    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        } else {
            search()
        }
    }, [pageNumber])

    // const handleSearch = (event: React.FormEvent<HTMLFormElement>, data: FormProps) => {

    const handlePageChange = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        data: PaginationProps
    ) => {
        setPageNumber(Number(data.activePage))
    }

    const handleSearchClick = () => {
        if (pageNumber === 1) {
            search()
        } else {
            setPageNumber(1)
        }
    }

    const handleDownloadClick = () => {
        const searchCriteria = gatherSearchCriteria()
        tipOrderService.exportQuery(searchCriteria).then(result => {
            if (result) {
                const blob = new Blob([result.data], { type: 'octet/stream' })
                FileSaver.saveAs(blob, result.fileName)
            }
        })
    }

    return (
        <>
            <Grid columns={2}>
                <Grid.Column width='3'>
                    <Grid.Row>
                        <Segment raised size='tiny'>
                            <Form onSubmit={handleSearchClick} size='tiny'>
                                <h2>
                                    <u>Filter &nbsp;&nbsp;&nbsp;</u>
                                </h2>
                                <Form.Field size={'miny'}>
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        placeholderText='Start Date'
                                    />
                                </Form.Field>
                                <Form.Field size={'miny'}>
                                    <label>End Date</label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        placeholderText='End Date'
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Employee ID</label>
                                    <Input
                                        placeholder='Employee ID'
                                        name='EmployeeId'
                                        value={employeeId}
                                        onChange={handleEmployeeIdChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>First Name</label>
                                    <Input
                                        placeholder='Employee First Name'
                                        name='FirstName'
                                        value={empFirstName}
                                        onChange={handleEmpFirstNameChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Last Name</label>
                                    <Input
                                        placeholder='Employee Last Name'
                                        name='LastName'
                                        value={empLastName}
                                        onChange={handleEmpLastNameChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Aggregator</label>
                                    <Dropdown
                                        style={{ minWidth: '12px' }}
                                        selection
                                        options={aggregatorOptions}
                                        value={aggregator}
                                        onChange={handleAggregatorChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Aggregator Order Number</label>
                                    <Input
                                        placeholder='Aggregator Order #'
                                        name='AggrOrderId'
                                        value={aggregatorOrderNumber}
                                        onChange={handleAggrOrderIdChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Order Number</label>
                                    <Input
                                        placeholder='Order #'
                                        name='OrderID'
                                        value={orderNumber}
                                        onChange={handlePanOrderIdChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Store</label>
                                    <Dropdown
                                        selection
                                        loading={loadingStores}
                                        options={stores.map(s => ({
                                            text: s.storeNumber,
                                            value: s.storeNumber
                                        }))}
                                        value={storeNumber}
                                        onChange={handleStoreChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Button loading={!loaded}>Search</Button>
                                </Form.Field>
                            </Form>
                        </Segment>
                    </Grid.Row>
                </Grid.Column>
                {tipOrders.length > 0 ? (
                    <Grid.Column width='12'>
                        <Container>
                            <Button
                                icon
                                onClick={handleDownloadClick}
                                labelPosition='left'
                                style={{ marginBottom: '12px' }}
                            >
                                <Icon name='download' />
                                Download this data
                            </Button>
                        </Container>
                        <Grid.Row>
                            <Dimmer active={!loaded}>
                                <Loader />
                            </Dimmer>
                            <div className='scrollable'>
                                <Table compact striped singleLine style={{ marginTop: 0 }}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Store Number</Table.HeaderCell>
                                            <Table.HeaderCell>Store Name</Table.HeaderCell>
                                            <Table.HeaderCell>Business Date</Table.HeaderCell>
                                            {/* <Table.HeaderCell>State</Table.HeaderCell> */}
                                            <Table.HeaderCell>Aggregator</Table.HeaderCell>
                                            <Table.HeaderCell>Order Time</Table.HeaderCell>
                                            <Table.HeaderCell>POS Order Number</Table.HeaderCell>
                                            <Table.HeaderCell>Ecomm Order Number</Table.HeaderCell>
                                            <Table.HeaderCell>External Order Number</Table.HeaderCell>
                                            <Table.HeaderCell>Driver Name</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Driver Employee Number
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>Total Amount</Table.HeaderCell>
                                            <Table.HeaderCell>Tips</Table.HeaderCell>
                                            <Table.HeaderCell>Tax Amount</Table.HeaderCell>
                                            <Table.HeaderCell>Delivery Fee</Table.HeaderCell>
                                            <Table.HeaderCell>Order Type</Table.HeaderCell>
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            {/* <Table.HeaderCell>Tax 1</Table.HeaderCell>
                      <Table.HeaderCell>Tax 2</Table.HeaderCell>
                      <Table.HeaderCell>Tax 3</Table.HeaderCell>
                      <Table.HeaderCell>Tax 4</Table.HeaderCell>
                      <Table.HeaderCell>Tax 5</Table.HeaderCell>
                      <Table.HeaderCell>Tax 6</Table.HeaderCell>
                      <Table.HeaderCell>Tax 7</Table.HeaderCell>
                      <Table.HeaderCell>Tax 8</Table.HeaderCell>
                      <Table.HeaderCell>Tax 9</Table.HeaderCell>
                      <Table.HeaderCell>Tax 10</Table.HeaderCell> */}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {tipOrders.map(
                                            (order): JSX.Element => (
                                                <Table.Row key={order.id}>
                                                    <Table.Cell>{order.cafeNumber}</Table.Cell>
                                                    <Table.Cell>{order.cafeName}</Table.Cell>
                                                    <Table.Cell>
                                                        {moment(order.businessDate).format('MM/DD/YYYY')}
                                                    </Table.Cell>
                                                    {/* <Table.Cell>{order.state}</Table.Cell> */}
                                                    <Table.Cell>{order.aggregator}</Table.Cell>
                                                    <Table.Cell>
                                                        {moment(order.orderTime).format('h:mm a')}
                                                    </Table.Cell>
                                                    <Table.Cell>{order.posOrderNumber}</Table.Cell>
                                                    <Table.Cell>{order.ecommOrderNumber}</Table.Cell>
                                                    <Table.Cell>{order.externalOrderNumber}</Table.Cell>
                                                    <Table.Cell>{order.driverName}</Table.Cell>
                                                    <Table.Cell>{order.driverEmployeeNumber}</Table.Cell>
                                                    <Table.Cell>{order.totalAmount}</Table.Cell>
                                                    <Table.Cell>{order.tips}</Table.Cell>
                                                    <Table.Cell>{order.taxAmount}</Table.Cell>
                                                    <Table.Cell>{order.deliveryFee}</Table.Cell>
                                                    <Table.Cell>{order.orderType}</Table.Cell>
                                                    <Table.Cell>{order.status}</Table.Cell>
                                                    {/* <Table.Cell>{order.tax1}</Table.Cell>
                          <Table.Cell>{order.tax2}</Table.Cell>
                          <Table.Cell>{order.tax3}</Table.Cell>
                          <Table.Cell>{order.tax4}</Table.Cell>
                          <Table.Cell>{order.tax5}</Table.Cell>
                          <Table.Cell>{order.tax6}</Table.Cell>
                          <Table.Cell>{order.tax7}</Table.Cell>
                          <Table.Cell>{order.tax8}</Table.Cell>
                          <Table.Cell>{order.tax9}</Table.Cell>
                      <Table.Cell>{order.tax10}</Table.Cell> */}
                                                </Table.Row>
                                            )
                                        )}
                                    </Table.Body>
                                </Table>
                            </div>
                            <Pagination
                                defaultActivePage={1}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </Grid.Row>
                    </Grid.Column>
                ) : (
                    <Grid.Column>
                        <h3> No data to display - see filter options</h3>
                    </Grid.Column>
                )}
            </Grid>
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(actionCreators, dispatch)
export default withRouter(connect(null, mapDispatchToProps)(TipOrderDashboard))

import React, { useState, useEffect, ChangeEvent, SyntheticEvent } from 'react'
import './Dashboard.css'
import tipsAllocationSummaryService, {
    tipsAllocationSummary,
    TipsAllocationSummarySearchCriteria
} from '../services/tipsAllocationSummaryService'
import {
    Table,
    Dimmer,
    Loader,
    Segment,
    Form,
    Input,
    Button,
    Grid,
    InputOnChangeData,
    Pagination,
    PaginationProps,
    Dropdown,
    DropdownProps,
    Icon
} from 'semantic-ui-react'
import { withRouter, RouteComponentProps } from 'react-router'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import FileSaver from 'file-saver'
import tiprStoreService, { TiprStore } from '../services/tiprStoreService'

const EmpHoursDashboard: React.FC<RouteComponentProps> = () => {

    const defaultStore: TiprStore = {
        id: 0,
        companyId: 0,
        paneraCompanyCode: '',
        _3PACompanyCode: '',
        storeNumber: 'All',
        franchiseStoreNumber: '',
        storeName: 'All',
        corporateName: '',
        franchise: '',
        hrCompanyCode: '',
        payrollWeek: '',
        openingDate: null,
        closingDate: null,
        dateCreated: null,
        dateImported: null
    }

    const [tipsAllocationSummary, setTipWorkstations] = useState(
        [] as tipsAllocationSummary[]
    )
    const [loaded, setLoaded] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [pageLoaded, setPageLoaded] = useState(false)
    const [storeNumber, setStoreNumber] = useState('')
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [stores, setStores] = useState<TiprStore[]>([defaultStore])
    const [loadingStores, setLoadingStores] = useState(true)
 


    useEffect(() => {
        tiprStoreService
            .getStoresForUser()
            .then(stores => {
                setStores([defaultStore, ...stores])
                setLoadingStores(false)
            })
            .catch(error => console.log('There was an error: ' + error))
    }, [])

    const handleStartDateChange = (value: Date) =>
        setStartDate(
            moment(value)
                .startOf('day')
                .toDate()
        )
    const handleEndDateChange = (value: Date) =>
        setEndDate(
            moment(value)
                .startOf('day')
                .toDate()
        )

    const handleStoreChange = (
        event: SyntheticEvent<HTMLElement>,
        data: DropdownProps
    ) => setStoreNumber(String(data.value))



    const gatherSearchCriteria = (): TipsAllocationSummarySearchCriteria | null => {
        const searchCriteria: TipsAllocationSummarySearchCriteria = { storeNumber }

        if (startDate !== null) {
            searchCriteria.startDate = startDate
        }

        if (endDate !== null) {
            searchCriteria.endDate = endDate
        }

        if (storeNumber !== '') {
            if (storeNumber.length === 4) {
                searchCriteria.storeNumber = '20' + storeNumber
            } else {
                searchCriteria.storeNumber = storeNumber
            }
        }

        return searchCriteria
    }

    const search = () => {
        setLoaded(false)
        const searchCriteria = gatherSearchCriteria()
        if (searchCriteria == null) {
            // set error message
            return
        }
        searchCriteria.pageNumber = pageNumber
        tipsAllocationSummaryService
            .searchEmpHours(searchCriteria)
            .then(pagedOrders => {
                setTipWorkstations(pagedOrders.data)
                setTotalPages(pagedOrders.totalPages)
            })
            .then(() => setLoaded(true))
    }

    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        } else {
            search()
        }
    }, [pageNumber])

    const handlePageChange = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        data: PaginationProps
    ) => {
        setPageNumber(Number(data.activePage))
    }

    const handleSearchClick = () => {
        if (pageNumber === 1) {
            search()
        } else {
            setPageNumber(1)
        }
    }

    const handleDownloadClick = () => {
        const searchCriteria = gatherSearchCriteria()
        tipsAllocationSummaryService.exportQuery(searchCriteria!).then(result => {
            if (result) {
                const blob = new Blob([result.data], { type: 'octet/stream' })
                FileSaver.saveAs(blob, result.fileName)
            }
        })
    }
    // Debug the  console.log() statement here
    //console.log('tipsAllocationSummary.length:', tipsAllocationSummary.length - 1);
    //console.log('tipsAllocationSummary.length:', tipsAllocationSummary.length);
    //console.log('tipsAllocationSummary.pageNumber:', pageNumber);
    //console.log('tipsAllocationSummary.totalPages:', totalPages);
    return (
        <>
            <Grid columns={2}>
                <Grid.Column width='3'>
                    <Grid.Row>
                        <Segment raised size='tiny'>
                            <Form onSubmit={handleSearchClick} size='tiny'>
                                <h2>
                                    <u>Filter &nbsp;&nbsp;&nbsp;</u>
                                </h2>
                                <Form.Field>
                                    <label>Store Number</label>
                                    <Dropdown
                                        selection
                                        loading={loadingStores}
                                        options={stores.map(s => ({
                                            text: s.storeNumber,
                                            value: s.storeNumber
                                        }))}
                                        name='StoreNum'
                                        value={storeNumber}
                                        onChange={handleStoreChange}
                                    />
                                </Form.Field>
                                <Form.Field size={'miny'}>
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        placeholderText='Start Date'
                                    />
                                </Form.Field>
                                <Form.Field size={'miny'}>
                                    <label>End Date</label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        placeholderText='End Date'
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Button loading={!loaded}>Search</Button>
                                </Form.Field>
                            </Form>
                        </Segment>
                    </Grid.Row>
                </Grid.Column>
                {tipsAllocationSummary.length > 0 ? (
                    <Grid.Column >
                        <Grid.Row>
                            <Button
                                icon
                                onClick={handleDownloadClick}
                                labelPosition='left'
                                style={{ marginBottom: '12px' }}
                            >
                                <Icon name='download' />
                                Download this data
                            </Button>
                        </Grid.Row>
                        <Grid.Row>
                            <Segment floated='left' size='small'>
                                <Dimmer active={!loaded}>
                                    <Loader />
                                </Dimmer>
                                <Table
                                    singleLine
                                    striped
                                    selectable
                                    unstackable
                                    style={{ marginTop: 0, backgroundColor: '#f7f7f7', tableLayout: 'auto', width: 'auto' }}
                                    size='small'
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Employee Id</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Employee Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Store</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Sunday</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Monday</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Tuesday</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Wednesday</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Thursday</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Friday</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>Saturday</Table.HeaderCell>
                                            <Table.HeaderCell style={{ backgroundColor: 'black', color: 'white' }}>WeekTotal</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {tipsAllocationSummary.map(
                                            (workstation, index): JSX.Element => (
                                                <Table.Row
                                                    key={workstation.id}
                                                    style={{ width: 'auto' }}
                                                    className={index === tipsAllocationSummary.length - 1 && pageNumber === totalPages ? "last-row" : ""}
                                                >
                                                    <Table.Cell className={index === tipsAllocationSummary.length - 1 && pageNumber === totalPages ? "last-row-first-column" : ""}>
                                                        {index === tipsAllocationSummary.length - 1 && pageNumber === totalPages ? "Week" : workstation.id}
                                                    </Table.Cell>
                                                    <Table.Cell>{workstation.employeeName}</Table.Cell>
                                                    <Table.Cell>
                                                        {index === tipsAllocationSummary.length - 1 && pageNumber === totalPages ? "" : workstation.cafeNumber}
                                                    </Table.Cell>
                                                    <Table.Cell>{workstation.sunday}</Table.Cell>
                                                    <Table.Cell>{workstation.monday}</Table.Cell>
                                                    <Table.Cell>{workstation.tuesday}</Table.Cell>
                                                    <Table.Cell>{workstation.wednesday}</Table.Cell>
                                                    <Table.Cell>{workstation.thursday}</Table.Cell>
                                                    <Table.Cell>{workstation.friday}</Table.Cell>
                                                    <Table.Cell>{workstation.saturday}</Table.Cell>
                                                    <Table.Cell>{workstation.weekTotal}</Table.Cell>
                                                </Table.Row>
                                            )
                                        )}
                                    </Table.Body>
                                </Table>
                                <Pagination
                                    defaultActivePage={1}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                            </Segment>
                        </Grid.Row>
                    </Grid.Column>
                ) : (
                    <Grid.Column>
                        <h3> No data to display - see filter options</h3>
                    </Grid.Column>
                )}
            </Grid>
        </>
    )
}

export default withRouter(EmpHoursDashboard)

import { Action, Reducer, Dispatch } from 'redux'
import { AppThunkAction } from './'
import userService, { TiprUser } from '../services/userService'
import { History } from 'history'
import jwtDecode from 'jwt-decode'
import { actionCreators as alertActionCreators } from './Alert'

type UserState = TiprUser | null

interface LoginSuccessAction {
  type: 'LOGIN_SUCCESS'
  user: TiprUser
}

interface LogoutAction {
  type: 'LOGOUT_SUCCESS'
}

interface LoginRequestAction {
  type: 'LOGIN_REQUEST'
  user: TiprUser
}

interface LoginFailureAction {
  type: 'LOGIN_FAILURE'
  message: string
}

interface TokenDTO {
  // eslint-disable-next-line camelcase
  unique_name: string
  Company: string
  nbf: number
  exp: number
  iat: number
}

export type AuthAction = LoginRequestAction | LoginSuccessAction | LoginFailureAction | LogoutAction

export interface AuthDispatchProps {
  login: (username: string, password: string) => AppThunkAction<AuthAction>
}

const request = (user: TiprUser): LoginRequestAction => ({ type: 'LOGIN_REQUEST', user })
const success = (user: TiprUser): LoginSuccessAction => ({ type: 'LOGIN_SUCCESS', user })
const failure = (error: string): LoginFailureAction => ({ type: 'LOGIN_FAILURE', message: error })

export const actionCreators = {
  login: (username: string, password: string, history: History) => {
    return (dispatch: Dispatch) => {
      dispatch(request({ username, name: '', company: '', token: '', companyId: 0, firstName: '', lastName: '' }))
      dispatch(alertActionCreators.clear())

      userService.login(username, password)
        .then(user => {
          localStorage.setItem('user', JSON.stringify(user))
          dispatch(success(user))
          history.push('/')
        },
        error => {
          dispatch(failure(error))
          dispatch(alertActionCreators.error('Invalid username or password'))
        })
    }
  },
  logout: (): LogoutAction => {
    userService.logout()
    return { type: 'LOGOUT_SUCCESS' }
  },
  loadSavedUser: () => {
    return (dispatch: Dispatch) => {
      const userJson = localStorage.getItem('user')
      if (userJson) {
        const user: TiprUser = JSON.parse(userJson)

        // Check that token is not expired
        const decoded: TokenDTO = jwtDecode(user.token)

        if (decoded.exp * 1000 > Date.now()) {
        // Token still valid
          dispatch(success(user))
        } else {
        // Token no longer valid
          localStorage.removeItem('user')
        }
      }
    }
  }
}

const unloadedState: UserState = null

export const reducer: Reducer<UserState> = (
  state: UserState | undefined,
  incomingAction: Action
): UserState => {
  if (state === undefined) {
    return unloadedState
  }

  const action = incomingAction as AuthAction
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return action.user
    case 'LOGIN_SUCCESS':
      return action.user
    case 'LOGIN_FAILURE':
      return unloadedState
    case 'LOGOUT_SUCCESS':
      return unloadedState
  }
  return state
}

import React, { useState, useEffect } from 'react'
import {
  Table,
  Dimmer,
  Loader,
  Segment,
  Form,
  Button,
  Grid,
  Icon,
  Pagination,
  PaginationProps,
  Container,
  Dropdown,
  DropdownProps
} from 'semantic-ui-react'
import reportService, {
  Discrepancy,
  DiscrepancySearchCriteria
} from '../services/reportService'
import FileSaver from 'file-saver'
import { TiprUser } from '../services/userService'
import moment from 'moment'

const companyOptions = [
  {
    key: 'BreakingBread',
    text: 'Breaking Bread',
    value: 14
  },
  {
    key: 'Covelli',
    text: 'Covelli',
    value: 4
  },
  {
    key: 'Doherty',
    text: 'Doherty',
    value: 25
  },
  {
    key: 'Flynn',
    text: 'Flynn',
    value: 16
  },
  {
    key: 'Manna',
    text: 'Manna',
    value: 2
  },
  {
    key: 'TXRG',
    text: 'Texas Restaurant Group',
    value: 7
  }
]

const DiscrepancyReportmenu = () => {
  const [selectedCompanyValue, setSelectedCompanyValue] = useState(
    companyOptions[0].value
  )
  const [loading, setLoading] = useState(false)

  const handleCompanyChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setSelectedCompanyValue(Number(data.value))
  }

  const runReport = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true)
    reportService
      .runDiscrepancyReport(selectedCompanyValue)
      .then(result => {
        if (result) {
          const blob = new Blob([result.data], { type: 'octet/stream' })
          FileSaver.saveAs(blob, result.fileName)
        } else {
          console.error('there was an error while generating the report')
        }
      })
      .then(() => setLoading(false))
      .catch(error => {
        setLoading(false)
        console.error(error)
      })
  }

  const userJson = localStorage.getItem('user') || ''
  const user: TiprUser = JSON.parse(userJson)

  const getCompanyOptionById = (id: number) => {
    return companyOptions.find(item => item.value === id)
  }

  useEffect(() => {
    const company = getCompanyOptionById(user.companyId)
    if (company && user.companyId !== 18) {
      setSelectedCompanyValue(company.value)
    }
  })

  const [discrepancies, setDiscrepancies] = useState([] as Discrepancy[])
  const [loaded, setLoaded] = useState(true)

  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageLoaded, setPageLoaded] = useState(false)
  const handlePageChange = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: PaginationProps
  ) => {
    setPageNumber(Number(data.activePage))
  }

  const gatherSearchCriteria = (): DiscrepancySearchCriteria => {
    const searchCriteria: DiscrepancySearchCriteria = {
      company: selectedCompanyValue
    }
    return searchCriteria
  }

  const search = () => {
    setLoaded(false)
    const searchCriteria = gatherSearchCriteria()
    searchCriteria.pageNumber = pageNumber

    reportService
      .searchDiscrepancy(searchCriteria)
      .then(pagedOrders => {
        setDiscrepancies(pagedOrders.data)
        setTotalPages(pagedOrders.totalPages)
      })
      .then(() => setLoaded(true))
  }

  const handleSearchClick = () => {
    if (pageNumber === 1) {
      search()
    } else {
      setPageNumber(1)
    }
  }

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true)
    } else {
      search()
    }
  }, [pageNumber])

  return (
    <>
      <Grid columns={2}>
        <Grid.Column width='3'>
          <Grid.Row>
            <Segment raised size='tiny'>
              <Form>
                <Form.Field>
                  <label>Company</label>
                  <Dropdown
                    selection
                    disabled={user.companyId !== 18}
                    options={companyOptions}
                    value={selectedCompanyValue}
                    onChange={handleCompanyChange}
                  />
                </Form.Field>
                <Button onClick={handleSearchClick} loading={loading}>
                  View Discrepancy Report
                </Button>
              </Form>
            </Segment>
          </Grid.Row>
        </Grid.Column>
        {discrepancies.length > 0 ? (
          <Grid.Column>
            <Container>
              <Button
                icon
                onClick={runReport}
                loading={loading}
                labelPosition='left'
                style={{ marginBottom: '12px' }}
              >
                <Icon name='download' />
                Download this data
              </Button>
            </Container>
            <Grid.Row>
              <Segment floated='left' size='small'>
                <Dimmer active={!loaded}>
                  <Loader />
                </Dimmer>
                <Table
                  singleLine
                  striped
                  selectable
                  unstackable
                  style={{ marginTop: 0 }}
                  size='small'
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Uber Business Date</Table.HeaderCell>
                      <Table.HeaderCell>Panera Business Date</Table.HeaderCell>
                      <Table.HeaderCell>Uber Store</Table.HeaderCell>
                      <Table.HeaderCell>Panera Store</Table.HeaderCell>
                      <Table.HeaderCell>Uber Order Number</Table.HeaderCell>
                      <Table.HeaderCell>Panera Order Number</Table.HeaderCell>
                      <Table.HeaderCell>Tips</Table.HeaderCell>
                      <Table.HeaderCell>Order Status</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {discrepancies.map(
                      (discrepancy): JSX.Element => (
                        <Table.Row key={JSON.stringify(discrepancy)}>
                          <Table.Cell>
                            {discrepancy.uberBusDate == null
                              ? '-'
                              : moment(discrepancy.uberBusDate).format(
                                  'MM/DD/YYYY'
                                )}
                          </Table.Cell>
                          <Table.Cell>
                            {discrepancy.paneraBusDate == null
                              ? '-'
                              : moment(discrepancy.paneraBusDate).format(
                                  'MM/DD/YYYY'
                                )}
                          </Table.Cell>
                          <Table.Cell>
                            {discrepancy.uberStore == null
                              ? '-'
                              : discrepancy.uberStore}
                          </Table.Cell>
                          <Table.Cell>
                            {discrepancy.panStore == null
                              ? '-'
                              : discrepancy.panStore}
                          </Table.Cell>
                          <Table.Cell>
                            {discrepancy.uberON == null
                              ? '-'
                              : discrepancy.uberON}
                          </Table.Cell>
                          <Table.Cell>
                            {discrepancy.paneraON == null
                              ? '-'
                              : discrepancy.paneraON}
                          </Table.Cell>
                          <Table.Cell>
                            {discrepancy.tips == null ? '-' : discrepancy.tips}
                          </Table.Cell>
                          <Table.Cell>
                            {discrepancy.orderStatus == null
                              ? '-'
                              : discrepancy.orderStatus}
                          </Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
                <Pagination
                  defaultActivePage={1}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </Segment>
            </Grid.Row>
          </Grid.Column>
        ) : (
          <Grid.Column>
            <h3>
              {' '}
              No data to display - Select a company to view and/or download data
            </h3>
          </Grid.Column>
        )}
      </Grid>
    </>
  )
}

export default DiscrepancyReportmenu

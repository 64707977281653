import React, { useState, useEffect } from 'react'
import { Grid, Header, Button, Form, Dropdown, DropdownProps, GridRow, Divider } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import exportService, { PayrollExportParameters } from '../services/exportService'
import FileSaver from 'file-saver'
import './Dashboard.css'
import Batches from './Batches'
import { TiprUser } from '../services/userService'
import moment from 'moment'
import FileImportEvents from './FileImportEvents'

const companyOptions = [
  {
    key: 14,
    text: 'Breaking Bread',
    value: 'BreakingBread'
  },
  {
    key: 4,
    text: 'Covelli',
    value: 'Covelli'
  },
  {
    key: 25,
    text: 'Doherty',
    value: 'Doherty'
  },
  {
    key: 16,
    text: 'Flynn',
    value: 'Flynn'
  },
  {
    key: 2,
    text: 'Manna',
    value: 'Manna'
  },
  {
    key: 7,
    text: 'Texas Restaurant Group',
    value: 'TXRG'
  }
]

const payrollWeekOptions = [
  {
    key: 'X',
    text: 'X',
    value: 'X'
  },
  {
    key: 'Y',
    text: 'Y',
    value: 'Y'
  }
]

const PayrollExportMenu = () => {
  const [cutoffDate, setCutoffDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [selectedCompanyValue, setSelectedCompanyValue] = useState(companyOptions[0].value)
  const [selectedPayrollWeek, setSelectedPayrollWeek] = useState(payrollWeekOptions[0].value)
  // This is used to trigger the batch view to update when a new batch is created
  const [update, setUpdate] = useState(0)

  const userJson = localStorage.getItem('user') || ''
  const user: TiprUser = JSON.parse(userJson)

  const getCompanyOptionById = (id: number) => {
    return companyOptions.find((item) => item.key === id)
  }

  useEffect(() => {
    const company = getCompanyOptionById(user.companyId) || companyOptions[0]
    setSelectedCompanyValue(company.value)
  })

  const handleCutoffDateChange = (value: Date) => setCutoffDate(moment(value).startOf('day').toDate())

  const handleCompanyChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    setSelectedCompanyValue(String(data.value))
  }

  const handlePayrollWeekChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    setSelectedPayrollWeek(String(data.value))
  }

  const runExport = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLoading(true)
    const params: PayrollExportParameters = { endDate: cutoffDate }
    if (selectedCompanyValue === 'Covelli') {
      params.payrollWeek = selectedPayrollWeek
    }
    exportService.runPayrollExport(selectedCompanyValue, params)
      .then(result => {
        if (result) {
          const blob = new Blob([result.data], { type: 'octet/stream' })
          FileSaver.saveAs(blob, result.fileName)
        } else {
          console.error('There was an error while downloading the export file')
        }
      })
      .then(() => {
        setUpdate((prev) => prev + 1)
        setLoading(false)
      })
  }

  return (
    <Grid columns={3} padded centered>
      <Grid.Column width="3">
        <Grid.Row>
          <Header dividing size="huge">
                  Create New Batch
          </Header>
        </Grid.Row>
        <Grid.Row>
          <Form widths="equal">
            <Form.Field>
              <label>Company</label>
              <Dropdown
                selection
                disabled={user.companyId !== 18}
                options={companyOptions}
                value={selectedCompanyValue}
                onChange={handleCompanyChange}
              />
            </Form.Field>
            <Form.Field>
              <label>
                          Cutoff Date
              </label>
              <DatePicker
                selected={cutoffDate}
                onChange={handleCutoffDateChange}
                placeholderText='Start Date'
              />
            </Form.Field>
            {selectedCompanyValue === 'Covelli' &&
                        <Form.Field>
                          <label>
                            Payroll Week
                          </label>
                          <Dropdown
                            selection
                            options={payrollWeekOptions}
                            value={selectedPayrollWeek}
                            onChange={handlePayrollWeekChange}
                          />
                        </Form.Field>
            }
            <Button onClick={runExport} loading={loading} disabled={user.companyId !== 18} >
                     Submit
            </Button>
          </Form>
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width="7" >
        <Header dividing size="huge">Batches</Header>
        <Batches update={update} />
      </Grid.Column>
      <Grid.Column >
        <Grid.Row>
          <Header style={{ paddingTop: '20px' }} dividing size="medium">Latest File Imports</Header>
        </Grid.Row>
        <Grid.Row>
          <FileImportEvents />
        </Grid.Row>
      </Grid.Column>
    </Grid>
  )
}

export default PayrollExportMenu

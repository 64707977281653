import React from 'react'
import { Menu, Button, Sticky, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './NavMenu.css'
import { useLocation, useHistory } from 'react-router'
import { TiprUser, login, logout } from '../services/userService'
import tiprLogo from '../Tipr.png'

const NavMenu = () => {
  const { pathname } = useLocation()
  const history = useHistory()

  const handleLogoutClick = () => {
    localStorage.removeItem('user')
    history.push('/login')
  }

  const userJson = localStorage.getItem('user') || ''
  const user: TiprUser = userJson ? JSON.parse(userJson) : undefined
  //console.log('User object:', user) //SMS tell Grace to not keep debugging especially sensitive data!
  const fullName = user ? `${user.firstName || ''} ${user.lastName || ''}` : ''

  return (
    <Sticky>
      <header>
        <Menu inverted fluid>
          <Menu.Menu>
            <Menu.Item name='TIPR'>
              <Image src={tiprLogo} size='small' />
              
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position='right'>
            <Menu.Item as='h2' name='User' content={fullName} />
            <Menu.Item
              style={{ marginRight: '0px', backgroundColor: 'tomato' }}
              className='logout'
              name='Logout'
              as={Button}
              onClick={handleLogoutClick}
            />
          </Menu.Menu>
        </Menu>
      </header>
    </Sticky>
  )
}

export default NavMenu

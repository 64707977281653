import React, { FC } from 'react'
import NavMenu from './NavMenu'
import { ApplicationState } from '../store'
import { AlertState } from '../store/Alert'
import { connect } from 'react-redux'
import Alert from './Alert'

type LayoutProps = {
  alert: AlertState
  children: React.ReactNode
}

const Layout: FC<LayoutProps> = (props: LayoutProps) => (
  <>
    <NavMenu />
    {props.alert.message
      ? <Alert message={props.alert.message}
        negative={props.alert.type === 'negative'} />
      : null
    }
    {props.children}
  </>
)

const mapState = (state: ApplicationState) => ({
  alert: state.alert
})

export default connect(mapState)(Layout)

import userService from '../services/userService'
import { AxiosError } from 'axios'

// If reponse is unauthorized, log out user so they have to authenticate again
const handleResponseAuthorization = (error: AxiosError) => {
  if (error && error.response && error.response.status === 401) {
    userService.logout()
    window.location.reload()
  }
  return error
}

export default handleResponseAuthorization

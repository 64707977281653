import Axios, { AxiosResponse } from 'axios'
import handleResponseAuthorization from '../utils/responseHelper'
import config from '../utils/config'
import authHelper from '../utils/authHelper'

const baseUrl = config.API_BASE_URL

export interface TiprStore {
  id: number
  companyId: number
  paneraCompanyCode: string
  _3PACompanyCode: string
  storeNumber: string
  franchiseStoreNumber: string | null
  storeName: string
  corporateName: string
  franchise: string
  hrCompanyCode: string
  payrollWeek: string
  openingDate: Date | null
  closingDate: Date | null
  dateCreated: Date | null
  dateImported: Date | null
}

interface TiprStoresResponse {
  data: TiprStore[]
  errorMessage: string
}

const getStoresForUser = () => {
  return Axios.get<TiprStore[]>(`${baseUrl}/store`, {
    headers: authHelper.authHeader()
  })
    .then((response: AxiosResponse<TiprStore[]>) => {
      if (!response.data) {
        throw new Error('No store data received.')
      }
      return response.data
    })
    .catch(error => {
      console.log(error)
      handleResponseAuthorization(error)
      throw error
    })
}

export default {
  getStoresForUser
}

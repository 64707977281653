import React, { useState, useEffect } from 'react'
import { Table } from 'semantic-ui-react'
import fileImportsService, { FileImport } from '../services/fileImportsService'
import moment from 'moment'

const FileImportEvents = () => {
  const [latestFiles, setLatestFiles] = useState([] as FileImport[])

  useEffect(() => {
    fileImportsService
      .getLastFiveFilesImported()
      .then(files => {
        setLatestFiles(files)
      })
  }, [])

  const headerRow = ['File Name', 'Action', 'Action Date']

  const renderBodyRow = ({ id, fileName, type, action, dateCreated, fileTimestamp }: FileImport) => ({
    key: id,
    error: action.match('Failed'),
    cells: [
      fileName, action, moment(dateCreated).format('YYYY-MM-DD hh:mm')
    ]
  })

  return (
    <Table
      size="small"
      compact
      singleLine
      headerRow={headerRow}
      renderBodyRow={renderBodyRow}
      tableData={latestFiles}
    />
  )
}

export default FileImportEvents

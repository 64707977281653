import React from 'react'
import { connect } from 'react-redux'
import { List, Segment, Header } from 'semantic-ui-react'

const Home = () => (
    <>
    <Segment vertical padded={false}>
      <Segment vertical size='small'>
        <Header>
      The new Microcon TIPR portal is designed to give you immediate access to many types of tip-related and other data:
        </Header>
        <List bulleted size='small'>
          <List.Item>3rd party aggregator (3PA) orders and tips for all aggregators (including new white-label delivery)</List.Item>
          <List.Item>Panera cafe-based delivery (CBD) orders and tips</List.Item>
          <List.Item>Cafe/team tips</List.Item>
          <List.Item>Catering tips (from Panera and CAM data)</List.Item>
          <List.Item>Employee hours worked by date, location and job code</List.Item>
        </List>
      </Segment>
      <Segment vertical size='small'>
        <Header>
      Data is available through the following canned reports:
        </Header>
        <List bulleted size='small'>
          <List.Item>Employee tips</List.Item>
          <List.Item>Employee hours worked</List.Item>
          <List.Item>Cafe tips</List.Item>
          <List.Item>Order lookup (Panera or 3PA)</List.Item>
          <List.Item>Panera/3PA reconciliation and exceptions</List.Item>
          <List.Item>CAM reports</List.Item>
        </List>
        <p>
      Additionally, a flexible search screen allows you to research orders, tips and hours using a variety of search criteria.
        </p>
      </Segment>
      <Segment vertical size='small'>
        <Header>
         Payroll exports include:
        </Header>
        <List bulleted size='small'>
          <List.Item>Uber Post tips</List.Item>
          <List.Item>Employee hours</List.Item>
          <List.Item>G/L exports</List.Item>
          <List.Item>CAM tips</List.Item>
          <List.Item>Cafe/team tips</List.Item>
          <List.Item>CBD tips</List.Item>
        </List>
      </Segment>
      <Segment vertical>
        <List.Item as="a" href="mailto:support@microcon.com">
              Contact Us
        </List.Item>
      </Segment>
    </Segment>
  </>
)

export default connect()(Home)

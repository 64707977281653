import React, { useState, useEffect, ChangeEvent } from 'react'
import './Dashboard.css'
import tipOrderService, {
    TipOrder,
    TipOrderSearchCriteria
} from '../services/tipOrderService'
import {
    Table,
    Dimmer,
    Loader,
    Segment,
    Form,
    Input,
    Button,
    Grid,
    InputOnChangeData,
    Icon,
    Pagination,
    PaginationProps,
    Container
} from 'semantic-ui-react'
import { withRouter, RouteComponentProps } from 'react-router'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import FileSaver from 'file-saver'

const OrderLookup: React.FC<RouteComponentProps> = () => {
    const aggregatorOptions = [
        {
            text: 'All',
            value: 'All'
        },
        {
            text: 'Doordash',
            value: 'DOORDASH'
        },
        {
            text: 'Doordash Pickup',
            value: 'Doordash Pickup'
        },
        {
            text: 'Google Food Delivery',
            value: 'Google Food Delivery'
        },
        {
            text: 'Google Food Pickup',
            value: 'Google Food Pickup'
        },
        {
            text: 'Grubhub',
            value: 'GRUBHUB'
        },
        {
            text: 'Grubhub Pickup',
            value: 'Grubhub Pickup '
        },
        {
            text: 'Panera',
            value: 'Panera'
        },
        {

            text: 'POSTMATES',
            value: 'POSTMATES'
        },
        {
            text: 'Postmates Pickup',
            value: 'Postmates Pickup'
        },
        {
            text: 'Uber Eats',
            value: 'Uber Eats'
        },
        {
            text: 'Uber Eats Pickup',
            value: 'Uber Eats Pickup'
        }
    ]

    const [tipOrders, setTipOrders] = useState([] as TipOrder[])
    const [loaded, setLoaded] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [pageLoaded, setPageLoaded] = useState(false)

    // Search parameters
    const [startDate] = useState<Date | null>(null)
    const [endDate] = useState<Date | null>(null)
    const [aggregator] = useState(aggregatorOptions[0].value)
    const [employeeId] = useState('')
    const [empFirstName] = useState('')
    const [empLastName] = useState('')

    const [aggregatorOrderNumber, setAggregatorOrderNumber] = useState('')
    const [orderNumber, setOrderNumber] = useState('')
    const [storeNumber] = useState('')

    const handleAggrOrderIdChange = (
        event: ChangeEvent<HTMLInputElement>,
        { value }: InputOnChangeData
    ) => setAggregatorOrderNumber(value)
    const handlePanOrderIdChange = (
        event: ChangeEvent<HTMLInputElement>,
        { value }: InputOnChangeData
    ) => setOrderNumber(value)

    const gatherSearchCriteria = (): TipOrderSearchCriteria => {
        const searchCriteria: TipOrderSearchCriteria = {}

        if (startDate !== null) {
            searchCriteria.startDate = startDate
        }
        if (endDate !== null) {
            searchCriteria.endDate = endDate
        }

        if (aggregator !== 'All') {
            searchCriteria.aggregator = aggregator
        }

        if (employeeId !== '') {
            searchCriteria.employeeId = Number(employeeId)
        }

        if (empFirstName !== '') {
            searchCriteria.firstName = empFirstName
        }

        if (empLastName !== '') {
            searchCriteria.lastName = empLastName
        }

        if (aggregatorOrderNumber !== '') {
            searchCriteria.aggrOrderId = aggregatorOrderNumber
        }

        if (orderNumber !== '') {
            searchCriteria.panOrderId = Number(orderNumber)
        }

        if (storeNumber !== '') {
            if (storeNumber.length === 4) {
                searchCriteria.storeNumber = '20' + storeNumber
            } else {
                searchCriteria.storeNumber = storeNumber
            }
        }

        return searchCriteria
    }

    const search = () => {
        setLoaded(false)
        const searchCriteria = gatherSearchCriteria()
        searchCriteria.pageNumber = pageNumber

        tipOrderService
            .searchTipOrders(searchCriteria)
            .then(pagedOrders => {
                setTipOrders(pagedOrders.data)
                setTotalPages(pagedOrders.totalPages)
            })
            .then(() => setLoaded(true))
    }

    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        } else {
            search()
        }
    }, [pageNumber])

    // const handleSearch = (event: React.FormEvent<HTMLFormElement>, data: FormProps) => {

    const handlePageChange = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        data: PaginationProps
    ) => {
        setPageNumber(Number(data.activePage))
    }

    const handleSearchClick = () => {
        if (pageNumber === 1) {
            search()
        } else {
            setPageNumber(1)
        }
    }

    const handleDownloadClick = () => {
        const searchCriteria = gatherSearchCriteria()
        tipOrderService.exportQuery(searchCriteria).then(result => {
            if (result) {
                const blob = new Blob([result.data], { type: 'octet/stream' })
                FileSaver.saveAs(blob, result.fileName)
            }
        })
    }

    return (
        <>
            <Grid columns={2}>
                <Grid.Column width='3'>
                    <Grid.Row>
                        <Segment raised size='tiny'>
                            <Form onSubmit={handleSearchClick} size='tiny'>
                                <h2>
                                    <u>Filter &nbsp;&nbsp;&nbsp;</u>
                                </h2>
                                <Form.Field>
                                    <label>Aggregator Order Number</label>
                                    <Input
                                        placeholder='Aggregator Order #'
                                        name='AggrOrderId'
                                        value={aggregatorOrderNumber}
                                        onChange={handleAggrOrderIdChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Order Number</label>
                                    <Input
                                        placeholder='Order #'
                                        name='OrderID'
                                        value={orderNumber}
                                        onChange={handlePanOrderIdChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Button loading={!loaded}>Search</Button>
                                </Form.Field>
                            </Form>
                        </Segment>
                    </Grid.Row>
                </Grid.Column>
                {tipOrders.length > 0 ? (
                    <Grid.Column>
                        <Container>
                            <Button
                                icon
                                onClick={handleDownloadClick}
                                labelPosition='left'
                                style={{ marginBottom: '12px' }}
                            >
                                <Icon name='download' />
                                Download this data
                            </Button>
                        </Container>
                        <Grid.Row>
                            <Segment floated='left' size='small'>
                                <Dimmer active={!loaded}>
                                    <Loader />
                                </Dimmer>
                                <Table
                                    singleLine
                                    striped
                                    selectable
                                    unstackable
                                    style={{ marginTop: 0 }}
                                    size='small'
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Store Number</Table.HeaderCell>
                                            <Table.HeaderCell>Business Date</Table.HeaderCell>
                                            <Table.HeaderCell>Employee Id</Table.HeaderCell>
                                            <Table.HeaderCell>Employee Name</Table.HeaderCell>
                                            <Table.HeaderCell>Tips</Table.HeaderCell>
                                            <Table.HeaderCell>Total Amount</Table.HeaderCell>
                                            <Table.HeaderCell>Order Number</Table.HeaderCell>
                                            <Table.HeaderCell>Aggregator</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Aggregator Order Number
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {tipOrders.map(
                                            (order): JSX.Element => (
                                                <Table.Row key={order.id}>
                                                    <Table.Cell width={2}>{order.cafeNumber}</Table.Cell>
                                                    <Table.Cell>
                                                        {moment(order.businessDate).format('MM/DD/YYYY')}
                                                    </Table.Cell>
                                                    <Table.Cell>{order.driverName}</Table.Cell>
                                                    <Table.Cell>{order.driverEmployeeNumber}</Table.Cell>
                                                    <Table.Cell>{order.tips}</Table.Cell>
                                                    <Table.Cell>{order.totalAmount}</Table.Cell>
                                                    <Table.Cell>{order.ecommOrderNumber}</Table.Cell>
                                                    <Table.Cell>{order.aggregator}</Table.Cell>
                                                    <Table.Cell>{order.externalOrderNumber}</Table.Cell>
                                                </Table.Row>
                                            )
                                        )}
                                    </Table.Body>
                                </Table>
                                <Pagination
                                    defaultActivePage={1}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                            </Segment>
                        </Grid.Row>
                    </Grid.Column>
                ) : (
                    <Grid.Column>
                        <h3> No data to display - see filter options</h3>
                    </Grid.Column>
                )}
            </Grid>
        </>
    )
}

// const RenderPageButton = (pageNumber: number, currentPage: number) => {
//   return (
//     <Menu.Item onClick={handlePageClick(pageNumber)} active={pageNumber === currentPage}/>
//   )
// }

export default withRouter(OrderLookup)

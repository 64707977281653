import { Action, Reducer } from 'redux'

export interface AlertState {
  type: string
  message: string
}

export interface SuccessAlertAction {
  type: 'ALERT_SUCCESS'
  message: string
}

export interface ErrorAlertAction {
  type: 'ALERT_ERROR'
  message: string
}

export interface ClearAlertAction {
  type: 'ALERT_CLEAR'
}

const success = (message: string) => ({ type: 'ALERT_SUCCESS', message })
const error = (message: string) => ({ type: 'ALERT_ERROR', message })
const clear = () => ({ type: 'ALERT_CLEAR' })

type AlertAction = SuccessAlertAction | ErrorAlertAction | ClearAlertAction

export const actionCreators = {
  success,
  error,
  clear
}

const unloadedState: AlertState = { type: '', message: '' }

export const reducer: Reducer<AlertState> = (
  state: AlertState = unloadedState,
  incomingAction: Action
): AlertState => {
  const action = incomingAction as AlertAction
  switch (action.type) {
    case 'ALERT_SUCCESS':
      return { message: action.message, type: 'positive' }
    case 'ALERT_ERROR':
      return { message: action.message, type: 'negative' }
    case 'ALERT_CLEAR':
      return unloadedState
  }
  return state
}

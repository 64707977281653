import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Grid } from 'semantic-ui-react';
import FileSaver from 'file-saver';
import '../JobPosting.css';
import config from '../utils/config';
import authHelper from '../utils/authHelper';
import handleResponseAuthorization from '../utils/responseHelper';
import { useHistory } from 'react-router'
import { TiprUser } from '../services/userService'

const baseUrl = config.API_BASE_URL;

// Define the structure of the report data
interface ReportData {
  NumberOfBalanceTrackRecord: number;
  NumberOfHarriJobPostingRecord: number;
  DeletedRecord: number;
  ErrorMessage: string;
}

const HarriJobPostings = () => {
  // State variables for handling loading status, error messages, and report data
  const [loaded, setLoaded] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [reportData, setReportData] = useState<ReportData | null>(null);
  const history = useHistory()
  const userJson = localStorage.getItem('user') || ''
  const user: TiprUser = JSON.parse(userJson)
  const hasAccess = user.companyId === 4 || user.companyId === 18

  useEffect(() => {
    if (!hasAccess) {
      history.push("/")
    }
  }, [])

  // Function to run the report
  const runReport = () => {
    setLoaded(false);
    axios.get(`${baseUrl}/HarriJobPosting/HarriJobPosting`, {
      responseType: 'json',
      headers: authHelper.authHeader()
    })
      .then(response => {
        // Extract and process response data
        const { csvData, numberOfBalanceTrackRecord, numberOfHarriJobPostingRecord, deletedRecord, errorMessage } = response.data;

        // Convert CSV data to Blob and trigger download
        const utf8Bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        const blobWithBom = new Blob([utf8Bom, csvData], { type: 'text/csv;charset=utf-8' });
        FileSaver.saveAs(blobWithBom, 'job_postings_report.csv');

        // Update report data in the state for display
        setReportData({
          NumberOfBalanceTrackRecord: numberOfBalanceTrackRecord,
          NumberOfHarriJobPostingRecord: numberOfHarriJobPostingRecord,
          DeletedRecord: deletedRecord,
          ErrorMessage: errorMessage
        });
      })
      .catch(error => {
        if (error.response) {          
          setErrorMessage(error.response.data); // assuming error message is in the response data
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          setErrorMessage("No response received from server");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          setErrorMessage(error.message);
        }
      })
      .finally(() => {
        // Reset the loading state
        setLoaded(true);
      });
  };

  // Component render
  return (
    <Grid columns={2} padded>
      <Grid.Column>
        {/* Button to trigger report generation */}
        <Grid.Row>
          <Form widths='equal'>
            <Form.Button loading={!loaded} onClick={runReport}>
              Download
            </Form.Button>
          </Form>
        </Grid.Row>
        <Grid.Row>
          <br></br>
          <a href="Documents/PaneraJP.pdf" target="_blank" rel="noopener noreferrer">
            Click here for instructions on downloading job postings and uploading them to Panera. 
          </a>
        </Grid.Row>
        {/* Display error message if it exists */}
        {errorMessage && (
          <Grid.Row>
            <div className="error-message">
              <p>Error: {errorMessage}</p>
            </div>
          </Grid.Row>
        )}
        {/* Display report summary if data is available */}
        {reportData && (
          <Grid.Row>
            <div className="report-summary">
              <h3>Report Summary</h3>
              <p>Balance Track Records: {reportData.NumberOfBalanceTrackRecord}</p>
              <p>Harri Job Posting Records: {reportData.NumberOfHarriJobPostingRecord}</p>
              <p>Deleted Records: {reportData.DeletedRecord}</p>
              <p> Message: {reportData.ErrorMessage}</p>              
            </div>
          </Grid.Row>
        )}
      </Grid.Column>

      {/* Placeholder for additional content */}
      <Grid.Column>
      
      </Grid.Column>
    </Grid>
  );
}

export default HarriJobPostings;

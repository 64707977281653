import Axios from 'axios'
import config from '../utils/config'
import authHelper from '../utils/authHelper'
import handleResponseAuthorization from '../utils/responseHelper'

const baseUrl = config.API_BASE_URL

export interface Batch {
  id: number
  cutoffDate: Date
  batchTotal: number
  payrollWeek?: 'X' | 'Y'
  companyId: number
  company: string
  dateCreated: Date
}

const getAllBatches = () => {
  return Axios.get<Batch[]>(`${baseUrl}/batch`, { headers: authHelper.authHeader() })
    .then(result => result)
    .then(result => {
      return result.data
    })
    .catch(error => {
      handleResponseAuthorization(error)
      return []
    })
}

const getExportedBatch = async (batchId: number) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Axios.get(`${baseUrl}/batch/${batchId}`, { responseType: 'arraybuffer', headers: authHelper.authHeader() })
    .then(response => {
      const fileName = response.headers['x-tipr-filename']
      const fileType = response.headers['content-type']
      return { fileName: fileName, data: response.data, fileType }
    })
    .catch(error => {
      handleResponseAuthorization(error)
    })
}

export default {
  getAllBatches,
  getExportedBatch
}

import React, { useEffect, useState } from 'react'
import { Form, Grid } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import reportService from '../services/reportService'
import FileSaver from 'file-saver'
import Alert from './Alert'

const MannaGLExport = () => {
    const [loaded, setLoaded] = useState(true)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [errorMessage, setErrorMessage] = useState('')

    const runReport = () => {
        if (startDate === null || endDate === null) {
            alert('Please select a start date and end date')
            return
        }
        setLoaded(false)
        reportService
            .mannaGLExport(startDate as Date, endDate as Date)
            .then(result => {
                if (result) {
                    const blob = new Blob([result.data], { type: 'octet/stream' })
                    FileSaver.saveAs(blob, result.fileName)
                } else {
                    console.error('There was an error while downloading the report.')
                    setLoaded(false)
                }
            })
            .then(() => {
                setLoaded(true)
            })
            .catch(error => {
                console.log(error)
                setErrorMessage(
                    'There was an error processing your request. Please contact support@microcon.com'
                )
                setLoaded(true)
            })
    }
    const handleStartDateChange = (value: Date) =>
        setStartDate(
            moment(value)
                .startOf('day')
                .toDate()
        )
    const handleEndDateChange = (value: Date) =>
        setEndDate(
            moment(value)
                .startOf('day')
                .toDate()
        )
    return (
        <Grid columns={2} padded>
            <Grid.Column>
                <Grid.Row>
                    <Form widths='equal'>
                        <Form.Field>
                            <label>Start Date</label>
                            <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                placeholderText='Start Date'
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>End Date</label>
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDateChange}
                                placeholderText='End Date'
                            />
                        </Form.Field>
                        <Form.Button loading={!loaded} onClick={runReport}>
                            Submit
                        </Form.Button>
                    </Form>
                </Grid.Row>
                <Grid.Row>
                    {errorMessage !== '' && (
                        <Alert negative={true} message={errorMessage} />
                    )}
                </Grid.Row>
            </Grid.Column>
        </Grid>
    )
}

export default MannaGLExport

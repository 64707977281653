import Axios, { AxiosResponse } from 'axios'
import handleResponseAuthorization from '../utils/responseHelper'
import config from '../utils/config'
import authHelper from '../utils/authHelper'

const baseUrl = config.API_BASE_URL

export interface AccountingPeriod {
  recid: number
  accountingPeriod: number
  accountingPeriodStart: Date
  accountingPeriodEnd: Date
  fiscalYear: number
}

interface AccountingPeriodResponse {
  data: AccountingPeriod[]
  errorMessage: string
}

const getLatestAccountingPeriods = () => {
  return Axios.get<AccountingPeriodResponse>(`${baseUrl}/accountingPeriods/latest?count=10`, { headers: authHelper.authHeader() })
    .then((response: AxiosResponse<AccountingPeriodResponse>) => {
      console.log(response.data)
      if (!!response.data.errorMessage || !response.data.data) {
        throw response.data.errorMessage
      } else if (!response.data.data) {
        throw new Error('No accounting period data received.')
      }
      return response.data.data
    })
    .catch(error => {
      handleResponseAuthorization(error)
      throw error
    })
}

export default {
  getLatestAccountingPeriods
}
